<template>
	<SettingsItemLayout>
		<template v-slot:bar>
			<v-tooltip top>
				<template v-slot:activator="{ on }">
					<v-btn
						v-on="on"
						icon
						:color="`${isDark ? 'grey lighten-3' : 'grey darken-3'}`"
						@click="handleRefresh"
					>
						<v-icon>refresh</v-icon>
					</v-btn>
				</template>
				{{ $t('tooltip.refresh') }}
			</v-tooltip>
		</template>
		<template v-slot:navigation>
			<v-list dense>
				<v-list-item
					@click="handleOpenRequisitionFormTemplate({ id: null })"
					:class="
						requisitionFormTemplate.id === null ? 'v-list-item--active' : ''
					"
				>
					<v-list-item-icon>
						<v-icon>add</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>{{
							$t('button.new-requisition-form-template')
						}}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item
					@click="handleOpenRequisitionFormTemplate({ id: item.id })"
					v-for="(item, index) in requisitionFormTemplates"
					:key="index"
					:class="
						requisitionFormTemplate.id === item.id ? 'v-list-item--active' : ''
					"
				>
					<v-list-item-icon>
						<v-badge
							v-if="!item.addButton"
							bottom
							:color="item.active ? 'green' : 'grey'"
							dot
							offset-x="10"
							offset-y="10"
						>
							<v-icon>folder_open</v-icon>
						</v-badge>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>{{ item.label }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>
		</template>
		<template>
			<v-card tile v-if="showTemplateDetails" elevation="0">
				<v-toolbar :color="`${isDark ? '' : 'grey lighten-3'}`" flat dense>
					<v-toolbar-title>
						{{ requisitionFormTemplate.label }}
					</v-toolbar-title>
					<v-spacer></v-spacer>

					<v-btn-toggle
						rounded
						dense
						v-model="viewMode"
						style="margin-right: 0.5rem"
					>
						<v-btn value="edit">
							<v-icon left>edit</v-icon>
							{{ $t('button.edit') }}
						</v-btn>
						<v-btn value="preview">
							<v-icon left>visibility</v-icon>
							{{ $t('button.preview') }}
						</v-btn>
					</v-btn-toggle>

					<v-btn @click="handleSubmit" color="primary" :disabled="previewMode">
						{{ $t('button.save-changes') }}
					</v-btn>
				</v-toolbar>
				<v-divider></v-divider>
				<v-card-text v-if="!previewMode">
					<ValidationObserver
						ref="dynamic_form_build"
						tag="form"
						@submit="handleSubmit"
						id="dynamic_form_build"
					>
						<v-container>
							<v-row>
								<v-col>
									<fieldset
										class="talentics-fieldset talentics-fieldset-paddings"
									>
										<legend>
											{{ $t('label.status') }}:
											{{
												requisitionFormTemplate.active
													? $t('label.active')
													: $t('label.inactive')
											}}
										</legend>
										<v-switch
											v-model="requisitionFormTemplate.active"
											color="success"
											class="v-input--template-switch"
											hide-details
											inset
											dense
										></v-switch>
									</fieldset>
								</v-col>
							</v-row>

							<v-row>
								<v-col>
									<ValidationProvider rules="required" v-slot="{ errors }">
										<v-text-field
											v-model="requisitionFormTemplate.label"
											:label="$t('label.label')"
											outlined
											hide-details
											:error-messages="errors"
											dense
										></v-text-field>
									</ValidationProvider>
								</v-col>
							</v-row>

							<v-row>
								<v-col>
									<v-divider></v-divider>
								</v-col>
							</v-row>

							<v-row>
								<v-col>
									<ValidationProvider rules="required" v-slot="{ errors }">
										<v-select
											v-model="requisitionFormTemplate.requisition_type"
											:label="$t('label.type')"
											:items="[
												{
													text: $t('text.new-job'),
													value: 'new_job',
												},
												{
													text: $t('text.new-offer'),
													value: 'new_offer',
												},
											]"
											outlined
											hide-details
											:error-messages="errors"
											dense
										></v-select>
									</ValidationProvider>
								</v-col>
							</v-row>

							<v-row>
								<v-col>
									<fieldset
										class="talentics-fieldset talentics-fieldset-paddings"
									>
										<legend>
											{{ $t('label.instructions-for-interviewers') }}
										</legend>
										<RichEditor
											v-model="requisitionFormTemplate.instructions"
											refName="instructions"
										></RichEditor>
									</fieldset>
								</v-col>
							</v-row>

							<v-row>
								<v-col>
									<fieldset
										class="talentics-fieldset talentics-fieldset-paddings"
									>
										<legend>
											{{ $t('label.form-items') }}
										</legend>
										<v-container>
											<v-row
												v-if="requisitionFormTemplate.json_schema.length > 0"
											>
												<v-col>
													<DynamicForm
														:jsonSchema="requisitionFormTemplate.json_schema"
														:contextSchema="
															requisitionFormTemplate.context_schema
														"
														:onChange="handleDynamicFormChange"
														:onDelete="handleDeleteFormItem"
														:onMove="handleDynamicFormMove"
													></DynamicForm>
												</v-col>
											</v-row>
											<v-row>
												<v-col cols="9">
													<v-select
														v-model="newFormItem"
														outlined
														hide-details
														dense
														:items="[
															{
																value: null,
																text: $t('label.please-select'),
															},
															{
																value: 'textBuilder',
																text: $t('text.single-line-uppercase'),
															},
															{
																value: 'textareaBuilder',
																text: $t('text.paragraph-uppercase'),
															},
															{
																value: 'wysiwygBuilder',
																text: $t('text.wysiwyg'),
															},
															{
																value: 'datePickerBuilder',
																text: $t('text.date-uppercase'),
															},
															{
																value: 'selectBuilder',
																text: $t('text.dropdown-uppercase'),
															},
															{
																value: 'fileBuilder',
																text: $t('text.file-uppercase'),
															},
															{
																value: 'switchBuilder',
																text: $t('text.yes-no-uppercase'),
															},
															{
																value: 'radioBuilder',
																text: $t('text.multiple-choice-uppercase'),
															},
															{
																value: 'checkboxBuilder',
																text: $t('text.checkbox-uppercase'),
															},
															{
																value: 'userPickerBuilder',
																text: $t('text.user-picker-uppercase'),
															},
														]"
													></v-select>
												</v-col>
												<v-col cols="3">
													<v-btn
														block
														@click="handleAddNewFormItem"
														:disabled="Boolean(!newFormItem)"
													>
														{{ $t('add') }}
													</v-btn>
												</v-col>
											</v-row>
										</v-container>
									</fieldset>
								</v-col>
							</v-row>
						</v-container>
					</ValidationObserver>
				</v-card-text>
				<v-card-text v-else>
					<ValidationObserver
						ref="dynamic_form_preview"
						tag="form"
						@submit="handleSubmit"
						id="dynamic_form_preview"
					>
						<v-container>
							<v-row
								v-if="
									requisitionFormTemplate.instructions !== '<p><br></p>' &&
									requisitionFormTemplate.instructions !== undefined
								"
							>
								<v-col v-html="requisitionFormTemplate.instructions"></v-col>
							</v-row>
							<v-row>
								<v-col>
									<DynamicForm
										:jsonSchema="completedRequisitionFormTemplate.json_schema"
										:contextSchema="
											completedRequisitionFormTemplate.context_schema
										"
										:onChange="handleDynamicFormPreviewChange"
									></DynamicForm>
								</v-col>
							</v-row>
						</v-container>
					</ValidationObserver>
				</v-card-text>
			</v-card>
		</template>
	</SettingsItemLayout>
</template>

<script>
import { mapState } from 'vuex';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import DynamicForm from '@/components/shared/dynamicForm/DynamicForm';
import RichEditor from '@/components/shared/richEditor/RichEditor';
import SettingsItemLayout from '../settingsItemLayout/SettingsItemLayout';

export default {
	name: 'RequisitionFormTemplates',
	components: {
		ValidationObserver,
		ValidationProvider,
		DynamicForm,
		RichEditor,
		SettingsItemLayout,
	},
	computed: {
		...mapState({
			users: state => state.users.items,
			requisitionFormTemplates: state => state.requisitionFormTemplates.items,
			isDark: state => state.currentUser.isDark,
		}),
	},
	data() {
		return {
			showTemplateDetails: false,
			previewMode: false,
			requisitionFormTemplate: {
				id: null,
				label: '',
				json_schema: [],
				context_schema: {},
				active: true,
				requisition_type: 'new_job',
			},
			newFormItem: null,
			completedRequisitionFormTemplate: {},
			viewMode: 'edit',
		};
	},
	methods: {
		handleClose() {
			this.viewMode = 'edit';
			this.showTemplateDetails = false;
		},
		async handleSubmit(evt) {
			if (evt) {
				evt.preventDefault();
			}
			const isValid = await this.$refs.dynamic_form_build.validate();
			if (isValid) {
				const { requisitionFormTemplate } = this;
				const { id } = requisitionFormTemplate;
				if (id) {
					this.$store.dispatch('requisitionFormTemplates/put', {
						items: [
							{
								...requisitionFormTemplate,
								created_json_schema: await this.getCreatedJsonSchema(),
							},
						],
					});
				} else {
					this.$store
						.dispatch('requisitionFormTemplates/post', {
							items: [
								{
									...requisitionFormTemplate,
									created_json_schema: await this.getCreatedJsonSchema(),
								},
							],
						})
						.then(response => {
							const { items } = response.data;
							if (items.length > 0) {
								const { id } = items[0];
								this.handleOpenRequisitionFormTemplate({ id });
							}
						});
				}
			}
		},
		handleOpenRequisitionFormTemplate({ id }) {
			if (id) {
				this.$store
					.dispatch('requisitionFormTemplates/get', {
						id,
						refresh: true,
					})
					.then(response => {
						const { items } = response.data;
						if (items.length > 0) {
							this.requisitionFormTemplate = { ...items[0] };
							this.showTemplateDetails = true;
							this.viewMode = 'edit';
							this.previewMode = false;
						}
					});
			} else {
				this.requisitionFormTemplate = {
					id: null,
					label: '',
					json_schema: [],
					context_schema: {},
					active: true,
				};
				this.showTemplateDetails = true;
				this.viewMode = 'edit';
				this.previewMode = false;
			}
		},
		handleAddNewFormItem() {
			const { newFormItem } = this;
			const rand = Math.floor(100000 + Math.random() * 9000000);
			this.requisitionFormTemplate = {
				...this.requisitionFormTemplate,
				json_schema: [
					...this.requisitionFormTemplate.json_schema,
					{
						type: newFormItem,
						paramName: `param_${rand}`,
					},
				],
				context_schema: {
					...this.requisitionFormTemplate.context_schema,
					[`param_${rand}`]: {
						type: newFormItem,
					},
				},
			};
			this.newFormItem = null;
		},
		handleDeleteFormItem({ formItemIndex, formItemKey }) {
			this.requisitionFormTemplate = {
				...this.requisitionFormTemplate,
				json_schema: this.requisitionFormTemplate.json_schema.filter(
					(item, index) => index !== formItemIndex,
				),
				context_schema: {
					...this.requisitionFormTemplate.context_schema,
					[formItemKey]: undefined,
				},
			};
		},
		handleDynamicFormChange({ newVal }) {
			this.requisitionFormTemplate = {
				...this.requisitionFormTemplate,
				context_schema: {
					...this.requisitionFormTemplate.context_schema,
					...newVal,
				},
			};
		},
		handleDynamicFormPreviewChange({ newVal }) {
			this.completedRequisitionFormTemplate = {
				...this.completedRequisitionFormTemplate,
				context_schema: {
					...this.completedRequisitionFormTemplate.context_schema,
					...newVal,
				},
			};
		},
		handleDynamicFormMove({ newVal }) {
			this.requisitionFormTemplate = {
				...this.requisitionFormTemplate,
				json_schema: [...newVal],
			};
		},
		async getCreatedJsonSchema() {
			const { json_schema, context_schema } = this.requisitionFormTemplate;
			return await Promise.all(
				json_schema.map(async item => {
					const contextItem = context_schema[item.paramName];
					const { type, templateId } = contextItem;
					if (type === 'scorecardBuilder') {
						const scorecardTemplateResponse = await this.$store.dispatch(
							'scorecardTemplates/get',
							{ id: templateId, refresh: true },
						);
						const { created_json_schema } =
							scorecardTemplateResponse.data.items[0];
						return {
							...item,
							...contextItem,
							type: item.type.substring(0, item.type.length - 7),
							json_schema: created_json_schema,
						};
					}
					return {
						...item,
						...contextItem,
						type: item.type.substring(0, item.type.length - 7),
						maxLength: contextItem.maxLength && parseInt(contextItem.maxLength),
						options:
							contextItem.options &&
							contextItem.options.map((item, index) => {
								return {
									value: index,
									label: item.optionLabel,
								};
							}),
					};
				}),
			);
		},
		handleRefresh() {
			this.$store.dispatch('requisitionFormTemplates/get', {
				updateStore: true,
				refresh: true,
			});
			this.handleOpenRequisitionFormTemplate({ id: null });
		},
	},
	created() {
		this.$store.dispatch('requisitionFormTemplates/get', {
			updateStore: true,
		});
		this.$store.dispatch('scorecardTemplates/get', { updateStore: true });
		this.showTemplateDetails = false;
	},
	mounted() {
		this.handleOpenRequisitionFormTemplate({ id: null });
	},
	watch: {
		viewMode: {
			handler: async function (newVal) {
				if (newVal === 'preview') {
					const isValid = await this.$refs.dynamic_form_build.validate();
					if (isValid) {
						this.completedRequisitionFormTemplate = {
							...this.requisitionFormTemplate,
							json_schema: await this.getCreatedJsonSchema(),
							context_schema: {},
						};
						this.previewMode = true;
					} else {
						this.viewMode = 'edit';
						this.previewMode = false;
					}
				} else {
					this.previewMode = false;
				}
			},
			deep: true,
			immediate: false,
		},
	},
};
</script>

<style lang="scss">
.v-input--template-switch {
	&.v-input--selection-controls {
		margin-top: 0;
		margin-left: 4px;
	}
}
</style>
