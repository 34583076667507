<template>
	<div>
		<ScheduleEmailMenu
			:candidate="candidate"
			:onStart="handleScheduleNewEmail"
		></ScheduleEmailMenu>

		<div v-if="emails.length > 0" style="margin-top: 1rem">
			<v-divider></v-divider>
			<div v-for="(email, index) in emails" :key="index">
				<div>
					<v-container>
						<v-row>
							<v-col cols="2">
								{{ $t('label.status') }}
							</v-col>
							<v-col cols="10">
								<v-chip :ripple="false">
									{{ getStatus({ status: email.status }) }}
								</v-chip>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="2">
								{{ $t('label.from') }}
							</v-col>
							<v-col cols="10">
								<v-chip :ripple="false">
									{{ email.sent_from }}
								</v-chip>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="2">
								{{ $t('label.to') }}
							</v-col>
							<v-col cols="10">
								<v-chip
									v-for="(item, item_index) in email.send_to"
									:key="item_index"
									:ripple="false"
								>
									{{ item }}
								</v-chip>
							</v-col>
						</v-row>
						<v-row v-if="email.cc.length > 0">
							<v-col cols="2">
								{{ $t('label.cc') }}
							</v-col>
							<v-col cols="10">
								<v-chip
									v-for="(item, item_index) in email.cc"
									:key="item_index"
									:ripple="false"
								>
									{{ item }}
								</v-chip>
							</v-col>
						</v-row>
						<v-row v-if="email.bcc.length > 0">
							<v-col cols="2">
								{{ $t('label.bcc') }}
							</v-col>
							<v-col cols="10">
								<v-chip
									v-for="(item, item_index) in email.bcc"
									:key="item_index"
									:ripple="false"
								>
									{{ item }}
								</v-chip>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="2">
								{{ $t('label.send-at') }}
							</v-col>
							<v-col cols="10">
								<v-chip :ripple="false">
									{{ formatDate({ timestamp: email.send_at }) }}
								</v-chip>
								<v-btn
									v-if="email.status === 'scheduled' || email.status === null"
									small
									style="margin-left: 1rem"
									dark
									@click="
										() =>
											handleCancelScheduledEmail({
												id: email.id,
											})
									"
								>
									<v-icon left>close</v-icon>
									{{ $t('button.cancel') }}
								</v-btn>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="2">
								{{ $t('label.subject') }}
							</v-col>
							<v-col cols="10">
								<div v-html="email.subject"></div>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="2">
								{{ $t('label.body') }}
							</v-col>
							<v-col cols="10">
								<div v-html="email.template"></div>
							</v-col>
						</v-row>
						<v-row
							v-if="email.attachment_files && email.attachment_files.length > 0"
						>
							<v-col cols="2">
								{{ $t('label.attachments') }}
							</v-col>
							<v-col cols="10">
								<v-list dense>
									<template v-for="(item, index) in email.attachment_files">
										<v-list-item :key="`attachment_${index}`">
											<v-list-item-avatar>
												<v-icon>attachment</v-icon>
											</v-list-item-avatar>

											<v-list-item-content>
												<v-list-item-title
													v-html="item.original_file_name"
												></v-list-item-title>
												<v-list-item-subtitle
													>{{ item.content_type }} -
													{{ item.size }}
													bytes</v-list-item-subtitle
												>
											</v-list-item-content>

											<v-list-item-action v-if="!readOnly">
												<v-menu bottom left>
													<template v-slot:activator="{ on }">
														<v-btn icon small v-on="on">
															<v-icon>more_vert</v-icon>
														</v-btn>
													</template>
													<v-list dense>
														<v-list-item
															@click="
																() =>
																	handleDownloadFile({
																		id: item.id,
																	})
															"
														>
															<v-list-item-icon>
																<v-icon>download</v-icon>
															</v-list-item-icon>
															<v-list-item-title>{{
																$t('button.download')
															}}</v-list-item-title>
														</v-list-item>
													</v-list>
												</v-menu>
											</v-list-item-action>
										</v-list-item>

										<v-divider :key="index"></v-divider>
									</template>
								</v-list>
							</v-col>
						</v-row>
					</v-container>
				</div>
				<v-divider></v-divider>
			</div>
		</div>
		<div v-else style="margin-top: 1rem">
			{{ $t('err.no-emails-found') }}
		</div>
		<Confirm
			v-if="isConfirmVisible"
			:onConfirm="handleConfirmDialogConfirm"
			:onClose="handleConfirmDialogClose"
			><div style="padding-top: 20px">
				{{ $t('text.confirm-cancelling-scheduled-email') }}
			</div></Confirm
		>
		<ScheduleEmailDialog
			v-if="isScheduleEmailDialogVisible"
			:email="email"
			:candidate="candidate"
			:onClose="handleScheduleEmailDialogClose"
		></ScheduleEmailDialog>
	</div>
</template>

<script>
import { format } from 'date-fns';
import Confirm from '@/components/shared/dialogs/confirm/Confirm';
import ScheduleEmailDialog from './scheduleEmailDialog/ScheduleEmailDialog';
import ScheduleEmailMenu from './scheduleEmailMenu/ScheduleEmailMenu';
import api from '@/api';

export default {
	name: 'Emails',
	props: {
		candidate: {
			type: Object,
			required: true,
		},
	},
	components: {
		Confirm,
		ScheduleEmailDialog,
		ScheduleEmailMenu,
	},
	data() {
		return {
			format,
			emails: [],
			isConfirmVisible: false,
			idToDelete: null,
			isScheduleEmailDialogVisible: false,
			email: null,
		};
	},
	methods: {
		formatDate({ timestamp = new Date(), format = 'dd.MM.yyyy HH:mm:ss' }) {
			const dateToFormat = new Date(timestamp);
			return this.format(dateToFormat, format);
		},
		getStatus({ status }) {
			const statusList = {
				null: 'scheduled',
				sent: 'sent',
				cancelled: 'cancelled',
				error: 'error',
				unknown_receiver: 'receiver email not found',
			};
			return this.$t(`text.${statusList[status]}`);
		},
		handleConfirmDialogConfirm() {
			this.$store
				.dispatch('emails/put', {
					items: [
						{
							id: this.idToDelete,
							status: 'cancelled',
							is_cancelled: true,
						},
					],
				})
				.then(() => {
					this.isConfirmVisible = false;
					this.getCandidateEmails();
				});
		},
		handleConfirmDialogClose() {
			this.isConfirmVisible = false;
		},
		handleCancelScheduledEmail({ id }) {
			this.isConfirmVisible = true;
			this.idToDelete = id;
		},
		getCandidateEmails() {
			const { candidateId } = this.$route.params;
			this.$store
				.dispatch('emails/get', {
					candidate_id: candidateId,
					refresh: true,
				})
				.then(response => {
					this.emails = response.data.items;
				});
		},
		handleScheduleEmailDialogClose({ needsRefresh = false }) {
			this.isScheduleEmailDialogVisible = false;
			if (needsRefresh) {
				this.getCandidateEmails();
			}
		},
		handleScheduleNewEmail({ email }) {
			this.email = email;
			this.isScheduleEmailDialogVisible = true;
		},
		async handleDownloadFile({ id }) {
			const base64Response = await api.files.base64.get({ id });
			const { base64, original_file_name, content_type } = base64Response.data;
			const base64Fetch = await fetch(`data:${content_type};base64,${base64}`);
			const blob = await base64Fetch.blob();
			const FileSaver = require('file-saver');
			FileSaver.saveAs(blob, original_file_name);
		},
	},
	watch: {
		'$route.params.candidateId': {
			handler: function (newVal, oldVal) {
				if (newVal && newVal !== oldVal) {
					this.getCandidateEmails();
				}
			},
			deep: true,
			immediate: true,
		},
	},
};
</script>
