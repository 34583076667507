<template>
	<fieldset class="talentics-fieldset">
		<legend>{{ $t('label.link-questions') }}</legend>
		<v-container fluid>
			<div v-if="applications?.length > 0">
				<div v-for="(question, index) in linkQuestionsMap" :key="index">
					<div v-if="question.linkQuestions.length > 0">
						<div style="margin: 0.5rem 0">
							<label style="font-size: 1.1rem; font-weight: 600">{{
								question.jobName
							}}</label>
						</div>

						<v-row
							v-for="(question, questionIndex) in question.linkQuestions"
							:key="questionIndex"
						>
							<v-col>
								<v-text-field
									v-model="question.displayValue"
									:label="question.label"
									flat
									hide-details
									outlined
									:error-messages="errors[0] || ''"
									:name="question.propName"
									dense
									:disabled="true"
									:placeholder="question.label"
									class="link-question-input"
								></v-text-field>
							</v-col>
						</v-row>
						<v-divider style="margin: 1.5rem 0" />
					</div>
				</div>
			</div>
		</v-container>
	</fieldset>
</template>

<script>
import { mapState } from 'vuex';

export default {
	name: 'LinkQuestionsForm',
	props: {
		candidate: {
			type: Object,
			required: false,
			default() {
				return {
					applications: [],
				};
			},
		},
		errors: {
			type: Object,
			default: () => ({}),
		},
	},
	computed: {
		...mapState({
			currentLang: state => state.currentUser.lang,
			jobs: state => state.jobs.items,
		}),
		locale() {
			return this.currentLang === 'tr' ? 'tr-TR' : 'en-US';
		},
		applications() {
			return this.candidate?.applications;
		},
		linkQuestionsMap() {
			return this.generateLinkQuestionsMapData(this.applications);
		},
	},
	methods: {
		generateLinkQuestionsMapData(applications) {
			return applications.map(application => {
				const jobName = this.getJobName(application.job_id);

				return {
					jobName,
					linkQuestions: application.link_questions.map(question => {
						const { value, propName } = question;
						let displayValue;

						if (value?.toLowerCase().includes('option_')) {
							application.link_questions_set.forEach(linkQuestionSetItem => {
								if (linkQuestionSetItem.propName === propName) {
									linkQuestionSetItem.options.forEach(option => {
										if (option.value === value) {
											displayValue = option.label;
										}
									});
								}
							});
						} else {
							displayValue = value;
						}

						return {
							...question,
							displayValue,
						};
					}),
					linkQuestionsSet: application.link_questions_set,
				};
			});
		},
		getJobName(jobId) {
			const { jobs } = this;
			const job = jobs.find(item => item.id === jobId);
			return job ? job.name : jobId;
		},
	},
	watch: {
		applications: {
			handler: function (newVal) {
				if (!newVal) return;
				if (newVal.length > 0) {
					const linkQuestiosMap = this.generateLinkQuestionsMapData(newVal);
					this.$emit('linkQuestionsMap', linkQuestiosMap);
				}
			},
			deep: true,
			immediate: true,
		},
	},
};
</script>
